@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  height: 100vh;
}

.targeted-button {
  background-color: rgb(8 145 178) !important;
  border-color: rgb(21 94 117) !important;
}

.dropped-key {
  transition: left 0.5s ease-in-out, top 0.5s ease-in-out,
    width 0.5s ease-in-out, height 0.5s ease-in-out, font-size 0.5s ease-in-out,
    opacity 0.5s ease-in-out !important;
}

.keyboard {
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

#root {
  height: 100%;
}

.cell-animation {
  animation: revealCharCell linear;
  animation-duration: 0.15s;
}

@keyframes revealCharCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
